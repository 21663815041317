import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { useState, useEffect } from "react";
import {useNotify, useGetOne, FormDataConsumer} from "react-admin";
import CollectionsIcon from '@mui/icons-material/Collections';
import React from 'react';
import dataProvider, {fetchData} from "../../utils/dataProvider";
import { ENTRYPOINT } from '../../config/entrypoint';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel';
import { LinearProgress, CircularProgress } from '@mui/material';

const style = {"color":"white","margin":"5px","padding":"10px","minWidth":"unset"};
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});



const UserViewer = (props) => {
    const { data, loading, error } = useGetOne('users', props.user);
    
    if (loading)
        return <CircularProgress style={{width: "16px", height: "16px", marginLeft: "10px", marginRight: "10px"}}/>;

    if (error) {
        return "Error";
    }


    return data.firstName + " " + data.lastName + " ("+ data.email +")"
}

const BtnSeeImgPrep = (props) =>{
    let id_prep = props.data.replace("#/order_preparations/%2Forder_preparations%2F", "").replace("/show", "");
    const [open, setOpen] = useState(false);
    const [data, setData] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [loadingSuppress, setLoadingSuppress] = useState(false);

    const [reload, setReload] = useState(false);
    const notify = useNotify();


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        setLoading(true);
        dataProvider.getList('media_preps', {
            pagination: {page: 1, perPage: 999},
            sort: {field: 'id', order: 'ASC'},
            filter: {'id_prep': "/order_preparations/"+id_prep}
        }).then((datas)=>{
            setData(datas.data);
            setLoading(false);
        });
    }, [open, reload, id_prep])

    function deleteThisPic(e){
        notify('Suppression de l\'image en cours', {type: 'success'});
        setLoadingSuppress(true);
        let target = e.target;
        let id_media_prep = target.getAttribute('id_media_prep');
        let FD = new FormData()
        fetchData(id_media_prep, 'DELETE', FD).then((response) => {
            return response.json()
        });
        setTimeout(() => {
            setLoadingSuppress(false);
            setReload(true);
        }, 2000)
    }

    let haveImages = (data.length > 0)? true: false;
    return(
    <>
        <Button style={style} variant="contained" onClick={handleClickOpen} component="label" size="small">
                    <CollectionsIcon />
        </Button>
        <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        >
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
            <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
            >
                <CloseIcon />
            </IconButton>
            
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Liste des images liée la préparation n°{id_prep}
            </Typography>
            <Button autoFocus variant="contained" style={style} onClick={handleClose}>
                Fermer
            </Button>
            </Toolbar>
        </AppBar>
        {loading? <LinearProgress style={{marginTop: "10px"}}/> :
            !haveImages? <h1>Pas d'image disponible</h1>:
            <div>
            
                <Carousel showArrows={true}>
                        {
                            data.map((item)=>{
                                return <div>
                                        <img alt="image_" src={ENTRYPOINT+"/prep/"+item.filePath} style={{width: "100%", maxHeight: "600px"}}/>
                                        <p className="legend">Prise le : <b>{item.date_created}</b> par <b><UserViewer user={item.user}/></b> <a style={{textDecoration: "none"}} target="_blank" rel="noopener noreferrer" href={ENTRYPOINT+"/prep/"+item.filePath}><Button>Ouvrir dans une nouvelle fenetre</Button></a> <Button id_media_prep={item['@id']} onClick={deleteThisPic} style={{backgroundColor: "red", marginLeft: "10px"}}>{loadingSuppress?<CircularProgress/>:"Supprimer"}</Button></p>
                                </div>
                            })
                        }
                </Carousel>
            
            </div>
        }
        </Dialog>
    </>) 
}
export default BtnSeeImgPrep;



