import {Box, CircularProgress, Fab} from "@mui/material";
import SquareData from "../../../component/presenter/square/SquareData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import * as React from "react";
import dataProvider from "../../../utils/dataProvider";
import { useState } from 'react';

const PaletteItemQuantity = (props) => {
    // const [quantityInPalette, setQuantityInPalette] = useState(0);
    let handleReloadBreadCrumb = props.handleReloadBreadCrumb
    const quantityInPalette = () => {
        for (const i in props.orderPreparationItem.paletteArray) {
            const p = props.orderPreparationItem.paletteArray[i]
            if (p['id'] === props.palette)
                return p['quantity'];
        }
        return 0;
    }
    const [defaultQty, setdefaultQty] = useState('0');

    const quantityTotalPrepared = props.orderPreparationItem.quantityTotalPrepared;
    const quantityTotalToPrepare = props.orderPreparationItem.quantityTotalToPrepare;

    const checkQuantityToPrepare = () => {
        if (props.quantityToPrepare > quantityTotalToPrepare) {
            props.setQuantityToPrepare(quantityTotalToPrepare);
        } else if (props.quantityToPrepare < -quantityInPalette()) {
            props.setQuantityToPrepare(-quantityInPalette());
        }
    }

    function containsAnyLetters(str) {
        return /[a-zA-Z]/.test(str);
    }
    const onQuantityInputChange = (input) => {
        
        let value = input.target.value;
        if(containsAnyLetters(value)){
            input.target.value = 0;
            setdefaultQty("0");
            return;
        }
        if(value == "-"){
            input.target.value = "-";
            setdefaultQty("-");
            return;
        }
        if(value == ""){
            return;
        }
        
        value = parseInt(value);
        setdefaultQty(value);
        if (value > quantityTotalToPrepare) {
            value = quantityTotalToPrepare;
        } else if (value < -quantityInPalette()) {
            value = -quantityInPalette();
        }
        props.setQuantityToPrepare(value);
        checkQuantityToPrepare();
    };

    const handleQuantityToPrepare = (minus = true, unity = false, quantity = null) => {
        if (minus) {
            if (unity) {
                if (props.quantityToPrepare > -quantityInPalette()) {
                    props.setQuantityToPrepare(props.quantityToPrepare - (quantity == null ? 1 : parseInt(quantity)))
                    setdefaultQty(props.quantityToPrepare - (quantity == null ? 1 : parseInt(quantity)));
                }
            } else {
                props.setQuantityToPrepare(-quantityInPalette())
                setdefaultQty(-quantityInPalette());
            }
        } else {
            if (unity) {
                if (props.quantityToPrepare < quantityTotalToPrepare) {
                    props.setQuantityToPrepare(props.quantityToPrepare + (quantity == null ? 1 : parseInt(quantity)))
                    setdefaultQty(props.quantityToPrepare + (quantity == null ? 1 : parseInt(quantity)));
                }
            } else {
                props.setQuantityToPrepare(quantityTotalToPrepare)
                setdefaultQty(quantityTotalToPrepare);
            }
        }
        checkQuantityToPrepare()
    }

    async function verifiyGoToNext(){
        let setLock = props.setLock;
        let setCurrentOrderPreparationItem = props.setCurrentOrderPreparationItem;
        let preparationsItemList = props.orderPreparation.items;
        
        let i = 0;
        return await preparationsItemList.forEach(async (item)=>{
                if(item !== props.orderPreparationItem.id){
                    await dataProvider.getOne('order_preparation_items', {
                        id: item
                    }).then(async ({data}) => {
                        if(data.prepared.percent !== 100 && data.priority === (props.orderPreparationItem.priority +1)){
                                i++;

                                handleReloadBreadCrumb();
                                setCurrentOrderPreparationItem(data);
                                setLock(true);
                            
                            
                        }
                    });
                }
        });

    }

   

    const submitPaletteItem = () => {
        props.setLoading(true);
        dataProvider.getOne('palettes', {
            id: props.palette
        }).then(({data}) => {
            // Vérification du poids de la palette par rapport au transporteur, maximum
            // if(props.quantityToPrepare > 0 && data.weight > props.orderPreparation.maxWeightPerPalette) {
            //     alert('Le poids de votre palette n°' + data.number + ' dépasse le seuil du transporteur. Merci de retirer des produits et de les déplacer vers une nouvelle palette.')
            //     props.setLoading(false)
            // } else {
                document.getElementById('form-paletteItem-submit').click();
                setdefaultQty("0");
                verifiyGoToNext();
            // }
        });
        
    }
    return (
        
        <Box sx={{display: 'flex', alignItems: 'flex-start', gap: 5}}>
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <SquareData title="Besoin" subTitle={props.orderPreparationItem.quantityNeeded.toString().toUpperCase()}
                            value={props.orderPreparationItem.quantity.toString().toUpperCase()}/>
                <Box sx={{display: 'inline-flex', gap: 1, marginTop: 4.5}}>
                    <Fab
                        onClick={() => handleQuantityToPrepare()}
                        disabled={props.lock} size={"large"} color="primary" aria-label="Retirer tous">
                        <FontAwesomeIcon icon="fa-solid fa-minus"/>
                        <FontAwesomeIcon icon="fa-solid fa-minus"/>
                    </Fab>

                    { !(props.orderPreparationItem.packagingDetails[0].trim() === "") && <Fab
                        onClick={() => handleQuantityToPrepare(true, true, props.orderPreparationItem.packagingDetails[1])}
                        disabled={props.lock} size={"large"} color="primary" aria-label={"Retier 1x" + props.orderPreparationItem.packagingDetails[0]}>
                        <FontAwesomeIcon icon="fa-solid fa-minus"/>
                        { props.orderPreparationItem.packagingDetails[0] }
                    </Fab>}
                    <Fab
                        onClick={() => handleQuantityToPrepare(true, true)}
                        disabled={props.lock} size={"large"} color="primary" aria-label="Retier">
                        <FontAwesomeIcon icon="fa-solid fa-minus"/>
                    </Fab>
                </Box>
                <Box sx={{mt: '55px'}}>
                    Produit sur la palette
                </Box>
            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <SquareData title="A préparer" subTitle={props.orderPreparationItem.quantityToPrepare.toString().toUpperCase()}
                            value={quantityTotalToPrepare.toString().toUpperCase()}/>
                <Box sx={{margin: 1}}>
                    <FontAwesomeIcon icon="fa-solid fa-arrow-down"/>
                </Box>
                <TextField
                    onChange={onQuantityInputChange}
                    InputProps={{
                        inputProps: {
                            disabled: props.lock,
                            style: { textAlign: 'center' }
                        }
                    }}
                    value={defaultQty}
                    sx={{maxWidth: '100px', textAlign: 'center'}}
                    id="outlined-number"
                    type="text"
                    hiddenLabel
                />
                <Box sx={{margin: 1}}>
                    <FontAwesomeIcon icon="fa-solid fa-plus"/>
                </Box>
                <TextField
                    type="number"
                    InputProps={{
                        inputProps: {
                            disabled: true,
                            style: { textAlign: 'center' }
                        }
                    }}
                    sx={{maxWidth: '100px', textAlign: 'center'}}
                    value={quantityInPalette()}
                />

            </Box>

            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <SquareData title="Préparé" color={quantityTotalToPrepare === 0 ? "success" : "danger"}
                            subTitle={props.orderPreparationItem.quantityPrepared.toString().toUpperCase()}
                            value={quantityTotalPrepared.toString().toUpperCase()}/>
                <Box sx={{display: 'inline-flex', gap: 1, marginTop: 4.5}}>
                    <Fab
                        onClick={() => handleQuantityToPrepare(false, true)}
                        disabled={props.lock} size={"large"} color="primary" aria-label="Ajouter">
                        <FontAwesomeIcon icon="fa-solid fa-plus"/>
                    </Fab>
                    { !(props.orderPreparationItem.packagingDetails[0].trim() === "") && <Fab
                        onClick={() => handleQuantityToPrepare(false, true, props.orderPreparationItem.packagingDetails[1])}
                        disabled={props.lock} size={"large"} color="primary" aria-label={"Ajouter 1x" + props.orderPreparationItem.packagingDetails[0]}>
                        <FontAwesomeIcon icon="fa-solid fa-plus"/>
                        { props.orderPreparationItem.packagingDetails[0] }
                    </Fab> }
                    <Fab
                        onClick={() => handleQuantityToPrepare(false, false)}
                        disabled={props.lock} size={"large"} color="primary" aria-label="Ajouter 2x">
                        <FontAwesomeIcon icon="fa-solid fa-plus"/>
                        <FontAwesomeIcon icon="fa-solid fa-plus"/>
                    </Fab>
                </Box>
                <Box sx={{mt: '30px'}}>
                    <Button disabled={props.lock || props.loading ? true : props.quantityToPrepare === 0} onClick={submitPaletteItem}
                            sx={{marginTop: 2}} variant="contained"
                            endIcon={<FontAwesomeIcon icon="fa-solid fa-paper-plane"/>}>
                        { props.loading ? <CircularProgress size={20}/> : 'OK' }
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default PaletteItemQuantity
