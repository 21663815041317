import {Box, ListItemAvatar, ListItemText, Chip} from "@mui/material";
import plural from "pluralize-fr";
import SmallSquareData from "../../../component/presenter/square/SmallSquareData";
import * as React from "react";
import {useState} from "react";
import ImageField from "../../../component/field/ImageField"
import {library} from "@fortawesome/fontawesome-svg-core";
import { faCircleCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
library.add(faCircleCheck, faCancel)

const ShowProduct = (props) => {
    const [displayed, setDisplayed] = useState(true);


    if(props.record == null)
        return null;

    
    var secondary = props.record.packagingType ? props.record.packagingType : ' '
        + props.record.packagingQuantity ? props.record.packagingQuantity : ' '
        + props.record.packagingUnit ? plural(props.record.packagingUnit).toLowerCase() : '';
    if(secondary === "0"){
        secondary = "";
    }
    const ChipTestAndDisplay = (props) =>{
        if(typeof props.custom !== 'undefined' && props.custom !== ""){
            return(<><br/><Chip label={props.custom} variant="outlined" /></>)
        }else if(typeof props.attributes !== 'undefined' && props.attributes !== "" && props.attributes.length > 0){
            
            if(props.attributes.length === 1){
                return(<><br/><Chip label={props.attributes[0].join("-")} variant="outlined" /></>)
            }else{
                return(<>
                {
                    props.attributes.map((item) =>{
                        return (<><br/><Chip label={item.join('-')} variant="outlined" /></>)
                    })
                }</>);
            }
        }else{
            return(<></>);
        }
    }
    console.log(props.record);
    return (
        <>
            <ImageField source="image" style={{marginRight: "10px"}} />
            <Box component="span" sx={{fontWeight: 'bold', textTransform: 'uppercase', width: "100%"}}>{props.record.name} <ChipTestAndDisplay custom={props.record.custom}/> <ChipTestAndDisplay attributes={props.record.attributesToArray}/></Box>
            <SmallSquareData sx={{marginTop: 0, marginLeft: 2, marginRight: 2}} bigger={true} color={"info"} subTitle={props.palette.quantityToString} value={props.palette.quantity}/>
        </>
    )
}

export default ShowProduct
